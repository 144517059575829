(function() {
  var __sections__ = {};
  (function() {
    for(var i = 0, s = document.getElementById('sections-script').getAttribute('data-sections').split(','); i < s.length; i++)
      __sections__[s[i]] = true;
    })();
    (function() {
  if (!__sections__["cart-Drawer"]) return;
  try {
    
window.SectionCartDrawer = (function(){
function SectionCartDrawer() {
  $('.featured-collection .atc__message--error').hide()
  $('.featured-collection .btn-add-tocart').show();

  VastaShop.Cart.current.items.forEach(function(item){
      $('.btn-add-tocart[data-variant-id="' + item.variant_id + '"][data-max="' + item.quantity + '"]').hide()
          .siblings('.atc__message--error').show()
  });
  this._run();
}

SectionCartDrawer.prototype = {
  _run: function() {
    update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);

    if(!disable_cartDrawer){
      var _add_to_cart = jQuery('.jq-cart-add-product');

      _add_to_cart.submit(function () {
        $('#add-to-cart-error').html('');
        jQuery('.btn-add-tocart').prop('disabled', true);
        jQuery('.btn-add-tocart .btn-progress').animate({ width: '30%'}, 250);

        VastaShop.Cart.addItem(jQuery(this).serialize()).then(function (prod, status) {
          if ('success' == status) {
            VastaShop.Cart.getAllItems().then(function(cart, status){
              if ('success' === status) {
				$('.featured-collection .atc__message--error').hide()
                $('.featured-collection .btn-add-tocart').show();

                cart.items.forEach(function(item){
                    $('.btn-add-tocart[data-variant-id="' + item.variant_id + '"][data-max="' + item.quantity + '"]').hide()
                        .siblings('.atc__message--error').show()
                });

                jQuery('.btn-add-tocart .btn-progress').animate({ width: '100%'}, 250, function(){
                    jQuery(this).css('width', '0').show();
                });

                VastaShop.Cart.current = cart;

                jQuery('.cupom-text').show();
                render_cart_drawer(VastaShop.Cart.current, jQuery('.list-products'), 0,'');

                update_shipping_bar(cart.total_price,cart.item_count);

                jQuery('.btn-add-tocart').prop('disabled', false);
                jQuery('body').addClass('cart-drawer-open cart-open');
                inventoryControl(prod.id);
              }
            }).catch(function(err) {
              console.log(err);
            });
          }
        }).catch(function(err) {
          if (err.status === 422) {
            var product_variants = $('#all-product-variants'),
                max_allowed = parseInt($('option:selected', product_variants).data('inventory')),
                variant_in_cart = VastaShop.Cart.current.items.filter(function(e){
                  return e.id == product_variants.val();
                }),
                quantity_limit_message;

            jQuery('.btn-add-tocart .btn-progress').animate({ width: '100%'}, 250, function(){
              jQuery(this).css('width', '0').show();
              $('.btn-add-tocart').attr('disabled', false);
            });
            
            if (variant_in_cart.length > 0) {
              quantity_limit_message = max_allowed - variant_in_cart[0].quantity;

              if (quantity_limit_message > 0) {
                quantity_limit_message = 'You can only add ' + quantity_limit_message + (quantity_limit_message != 1 ? ' units of this product variants.': 'unit of this product variant.');
              } else {
                quantity_limit_message = 'This variant can\'t be added anymore';
              }

              $('#add-to-cart-error').html( quantity_limit_message );
            }
          } else {
            throw err;
          }
        });

        return false;
      });
    }

    $('.list-products').on('click','.cart-product-wrapper',function(){
      if($(this).find('.jq-plus-cart-item').attr('disabled') == 'disabled'){
          $(this).find('.jq-plus-cart-item').removeAttr('disabled');
      }
    });

    if (!jQuery('body').hasClass('template-cart')) {
      jQuery('.list-products').click('.jq-minus-cart-item', function (e) {
        var item = jQuery(e.target).eq(0);

        if (item.hasClass('jq-plus-cart-item')) {
            plusItem(item, '.input-qtd');
        } else if (item.hasClass('jq-minus-cart-item')) {
            minusItem(item, '.input-qtd');
        } else if (item.hasClass('bt-remove-cart')){
          removeItem('.jq-remove-cart-item', '#jq-cart-item-', '.jq-total-price', item.attr('name'), item);

          setTimeout(function(){
			  console.log(">>", item.attr('name'));
              $('.btn-add-tocart[data-variant-id="' + item.attr('name') + '"]').show()
                  .siblings('.atc__message--error').hide()
          }, 400);
          return false;
        }
      });

      jQuery('.list-products').on('submit', '.jq-qtd-item-cart', function(e){
        var data = $(this).serializeArray();
        var OlD = $(this).find('.input-qtd').prop('defaultValue');

        $('#CartDrawer form').attr('disabled', 'disabled');

        VastaShop.Cart.change(data).then(function(cart){
          VastaShop.Cart.current = cart;
          render_cart_drawer( cart, jQuery('.list-products'),OlD, data[0].value.split(':')[0] );
          inventoryControl(data[0].value.split(':')[0]);
          
          update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
        }).catch(function(err) {
            console.log(err);
        });

        return false;
      });
    }

    if (!jQuery('body').hasClass('template-cart')){
      jQuery('.list-products').on('change ', '.input-qtd', function (e) {
        var input_qtd = jQuery(this).eq(0),
            item = jQuery(e.target).eq(0);

        if(input_qtd.val() < 1){
          input_qtd.val(1);
        }

        if(input_qtd.val() >= parseInt(input_qtd.attr('data-max')) && parseInt(input_qtd.attr('data-max')) != -1){
          input_qtd.val(parseInt(input_qtd.attr('data-max')));
          input_qtd.parent().find('.jq-minus-cart-item').removeAttr('disabled');
        }else if(input_qtd.val() < parseInt(input_qtd.attr('data-max')) && parseInt(input_qtd.attr('data-max')) != -1){
          input_qtd.parent().find('.jq-plus-cart-item').removeAttr('disabled');
          input_qtd.parent().find('.jq-minus-cart-item').removeAttr('disabled');
        }

        jQuery('.list-products').trigger('submit');
      });
    }

    jQuery('.list-products').on('blur ', '.input-qtd', function (e) {
      jQuery(this).submit();
    })

    jQuery('.main-header .open-drawer').click(function(){
      if( !jQuery('body').hasClass('cart-drawer-open') && $(this).is('span'))
        jQuery('body').addClass('cart-drawer-open cart-open');
    });

    jQuery('.cart-drawer .fallback-text').click(function(){
      if( jQuery('body').hasClass('cart-open') )
        jQuery('body').removeClass('cart-open cart-drawer-open');
    });
  },

  onLoad: function(){
    update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
  },

  onSelect: function() {
    this._run();
  },

  onUnload: function() {
    jQuery('.main-header .open-drawer').off('click');
    jQuery('.cart-drawer .fallback-text').off('click');
    jQuery('.list-products').off('click');
  }
};

return new SectionCartDrawer;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-accordion"] && !window.DesignMode) return;
  try {
    

(function($){
    var $document = $(document);

    $document.ready(function(){
        $document.on('click', '.tabs', function(){
            var tabs = $(this),
                scope = tabs.parent().attr('data-tabs-scope');

            tabs.addClass('active').siblings().removeClass('active');

            $('.tabs-content[data-tabs-scope="' + scope + '"] .tab-content[data-identifier="' + tabs.attr('data-target') + '"]').addClass('active').siblings().removeClass('active');

            return false;
        });

        $(document).on('click', '.tabs-accordion-title', function(){
            var self = $(this),
                accordion = self.closest('.tabs-accordion'),
                item = self.closest('.tabs-accordion-title'),
                content = self.siblings('.tabs-accordion-content');

            var allTitles = accordion.find('.tabs-accordion-title').not(item),
                allContents = accordion.find('.tabs-accordion-content').not(content);

            allTitles.removeClass('active');
            allContents.slideUp();

            item.addClass('active');
			item.toggleClass('opened');
            content.slideToggle();
        });
    });
})(jQuery);

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-footer"]) return;
  try {
    
window.SectionFooter = (function(){
  function SectionFooter() {
    this._run();
  }

  SectionFooter.prototype = {
    _run: function() {

      if ( window.innerWidth < 768 )
        $('.site-footer .menu-footer-grid').addClass('clickable');
      else
		    $('.site-footer .menu-footer-grid').removeClass('clickable');

		$(window).resize(function(){
      if ( window.innerWidth < 768 ) {
        $('.site-footer .menu-footer-grid').addClass('clickable');
        $('.site-footer .menu-footer-grid .no-bullets').hide();
      } else {
        $('.site-footer .menu-footer-grid').removeClass('clickable');
        $('.site-footer .menu-footer-grid .no-bullets').show();
		  }
		});

		$('.site-footer .menu-footer-grid').click(function(){
		  	if (window.innerWidth < 768 && $(this).hasClass('clickable')) {

          $('.site-footer .menu-footer-grid .no-bullets').stop().slideUp();

          if ($('.no-bullets', this).is(':visible')){
            $('.no-bullets', this).stop().slideUp();
            $('.nav-title', this).removeClass('row-rotate');
          }else {
            $('.nav-title', this).addClass('row-rotate');
            $('.no-bullets', this).stop().slideDown();
            $(this).siblings().children('.nav-title').removeClass('row-rotate');
        }
      }
      });
    },

    onLoad: function() {
      this._run();
    },

    onUnload: function() {

    }
  };

  return new SectionFooter;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-header"]) return;
  try {
    
window.SectionHeader = (function(){
  function SectionHeader() {
    this._run();
  }

  SectionHeader.prototype = {
    _run: function() {
      $(document).on('click', '.main-header .cart-icon', function(){
        if( !$('body').hasClass('cart-drawer-open') )
          $('body').addClass('cart-drawer-open cart-open');
      });

      $(document).on('click', '.cart-drawer .fallback-text', function(){
        if( $('body').hasClass('cart-open') )
          $('body').removeClass('cart-open cart-drawer-open');
      });

      $(document).on('click', '.DrawerOverlay', function(){
        $('body').removeClass('js-drawer-open-left cart-open cart-drawer-open');
      });
    },

    onSelect: function() {
      this._run();
    },

    onUnload: function() {
      $('.main-header .cart-icon').off('click');
      $('.cart-drawer .fallback-text').off('click');
      $('.DrawerOverlay').off('click');
    }
  };

  return new SectionHeader;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-product-tabs"]) return;
  try {
    
  (function($){


      window.addEventListener('load', function(){
          $('.tabs-accordion-content').each(function(index){
              if(index==0){
              $(this).css('display', 'block')    
              }
          })	

          $('.tabs-accordion-title').each(function(index){
              if(index==0){
              $(this).addClass('opened');   
              }
          })

      })

      var $document = $(document);
      $document.ready(function(){
          $document.on('click', '.tabs', function(){
              var tabs = $(this),
                  scope = tabs.parent().attr('data-tabs-scope');

              tabs.addClass('active').siblings().removeClass('active');

              $('.tabs-content[data-tabs-scope="' + scope + '"] .tab-content[data-identifier="' + tabs.attr('data-target') + '"]').addClass('active').siblings().removeClass('active');

              return false;
          });


          $(document).find('.tabs-accordion-content').hide();
          $(document).on('click', '.tabs-accordion-title', function(){
             
              // var self = $(this),
              //     accordion = self.closest('.tabs-accordion'),
              //     item = self.closest('.tabs-accordion-title'),
              //     content = self.siblings('.tabs-accordion-content');

              // var allTitles = accordion.find('.tabs-accordion-title').not(item),
              //     allContents = accordion.find('.tabs-accordion-content').not(content);

              // allTitles.removeClass('active');
              // allTitles.removeClass('opened');
              // allContents.slideUp();
              // $('.tabs-accordion-title').removeClass('opened');
              // item.addClass('active');
              // item.addClass('opened');
              // if(item.hasClass('opened')){
              //   item.removeClass('opened');
              // } 
              // content.slideToggle();

              if( $(this).hasClass("opened") ){
              $(this).removeClass('active');
              $(this).removeClass('opened');
                
              $(this).next('.tabs-accordion-content').slideUp(300, function() {
              $(this).next('.tabs-accordion-content').css('display', 'none');
              });
              }
              else {
              $('.tabs-accordion-title').removeClass('active');
              $('.tabs-accordion-title').removeClass('opened');
              // $('.faq-detail').removeClass('active');
              $('.tabs-accordion-content').css('display', 'none');
              $(this).addClass('active');
              $(this).addClass('opened');
              $(this).next('.tabs-accordion-content').slideDown().css('display','block');
              
              }
          });
      });
  })(jQuery);

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-shipping-bar"]) return;
  try {
    
  window.SectionShippingBar = (function(){
    function SectionShippingBar() {
      this._run();
    }
  
    SectionShippingBar.prototype = {
      _run: function() {
        update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
      },
  
      onSelect: function() {
        this._run();
      },
  
      onUnload: function() {
  
      }
    };
  
    return new SectionShippingBar;
    })();
  
  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["footer"]) return;
  try {
    
window.SectionFooter = (function(){
  function SectionFooter() {
    this._run();
  }

  SectionFooter.prototype = {
    _run: function() {

      if ( window.innerWidth < 768 )
        $('.site-footer .menu-footer-grid').addClass('clickable');
      else
		    $('.site-footer .menu-footer-grid').removeClass('clickable');

		$(window).resize(function(){
      if ( window.innerWidth < 768 ) {
        $('.site-footer .menu-footer-grid').addClass('clickable');
        $('.site-footer .menu-footer-grid .no-bullets').hide();
      } else {
        $('.site-footer .menu-footer-grid').removeClass('clickable');
        $('.site-footer .menu-footer-grid .no-bullets').show();
		  }
		});

		$('.site-footer .menu-footer-grid').click(function(){
		  	if (window.innerWidth < 768 && $(this).hasClass('clickable')) {

          $('.site-footer .menu-footer-grid .no-bullets').stop().slideUp();

          if ($('.no-bullets', this).is(':visible')){
            $('.no-bullets', this).stop().slideUp();
            $('.nav-title', this).removeClass('row-rotate');
          }else {
            $('.nav-title', this).addClass('row-rotate');
            $('.no-bullets', this).stop().slideDown();
            $(this).siblings().children('.nav-title').removeClass('row-rotate');
        }
      }
      });
    },

    onLoad: function() {
      this._run();
    },

    onUnload: function() {

    }
  };

  return new SectionFooter;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["header"]) return;
  try {
    
window.SectionHeader = (function(){
  function SectionHeader() {
    this._run();
  }

  SectionHeader.prototype = {
    _run: function() {
      $(document).on('click', '.main-header .cart-icon', function(){
        if( !$('body').hasClass('cart-drawer-open') )
          $('body').addClass('cart-drawer-open cart-open');
      });

      $(document).on('click', '.cart-drawer .fallback-text', function(){
        if( $('body').hasClass('cart-open') )
          $('body').removeClass('cart-open cart-drawer-open');
      });

      $(document).on('click', '.DrawerOverlay', function(){
        $('body').removeClass('js-drawer-open-left cart-open cart-drawer-open');
      });
    },

    onSelect: function() {
      this._run();
    },

    onUnload: function() {
      $('.main-header .cart-icon').off('click');
      $('.cart-drawer .fallback-text').off('click');
      $('.DrawerOverlay').off('click');
    }
  };

  return new SectionHeader;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["menu-mobile"]) return;
  try {
    
$(document).on('click', '.jq-icon-fallback-text', function(){
  if($('body').hasClass('js-drawer-open-left'))
    $('body').removeClass('js-drawer-open-left');
  else {
    $('.main-content').addClass('is-moved-by-drawer');
    $('.fixed').addClass('is-moved-by-drawer');
    $('.shopify-section header').addClass('is-moved-by-drawer');
    $('.search').addClass('is-moved-by-drawer');
    $('body').addClass('js-drawer-open-left');
  }
});

$(document).on('click', '.DrawerOverlay', function(){
  $('body').removeClass('js-drawer-open-left cart-open cart-drawer-open');
});

$(document).on('click', '.mobile-nav__toggle-open,.mobile-nav__link[href^="#"]', function(){
  var instance = $(this);

  instance.parent().siblings('.child').slideToggle(400);

  if (instance.is('.mobile-nav__link')) {
    instance = instance.siblings('.mobile-nav__toggle-open');;
  }

  if( instance.hasClass('anime-plus-ver') ) {
    instance.removeClass('anime-plus-ver anime-plus-hor');
  } else {
    instance.addClass('anime-plus-ver anime-plus-hor');  
  }
});

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["new-custom-product-tabs"]) return;
  try {
    
  (function($){


      window.addEventListener('load', function(){
          $('.tabs-accordion-content').each(function(index){
              if(index==0){
              $(this).css('display', 'block')    
              }
          })	

          $('.tabs-accordion-title').each(function(index){
              if(index==0){
              $(this).addClass('opened');   
              }
          })

      })

      var $document = $(document);
      $document.ready(function(){
          $document.on('click', '.tabs', function(){
              var tabs = $(this),
                  scope = tabs.parent().attr('data-tabs-scope');

              tabs.addClass('active').siblings().removeClass('active');

              $('.tabs-content[data-tabs-scope="' + scope + '"] .tab-content[data-identifier="' + tabs.attr('data-target') + '"]').addClass('active').siblings().removeClass('active');

              return false;
          });


          $(document).find('.tabs-accordion-content').hide();
          $(document).on('click', '.tabs-accordion-title', function(){
             
              // var self = $(this),
              //     accordion = self.closest('.tabs-accordion'),
              //     item = self.closest('.tabs-accordion-title'),
              //     content = self.siblings('.tabs-accordion-content');

              // var allTitles = accordion.find('.tabs-accordion-title').not(item),
              //     allContents = accordion.find('.tabs-accordion-content').not(content);

              // allTitles.removeClass('active');
              // allTitles.removeClass('opened');
              // allContents.slideUp();
              // $('.tabs-accordion-title').removeClass('opened');
              // item.addClass('active');
              // item.addClass('opened');
              // if(item.hasClass('opened')){
              //   item.removeClass('opened');
              // } 
              // content.slideToggle();

              if( $(this).hasClass("opened") ){
              $(this).removeClass('active');
              $(this).removeClass('opened');
                
              $(this).next('.tabs-accordion-content').slideUp(300, function() {
              $(this).next('.tabs-accordion-content').css('display', 'none');
              });
              }
              else {
              $('.tabs-accordion-title').removeClass('active');
              $('.tabs-accordion-title').removeClass('opened');
              // $('.faq-detail').removeClass('active');
              $('.tabs-accordion-content').css('display', 'none');
              $(this).addClass('active');
              $(this).addClass('opened');
              $(this).next('.tabs-accordion-content').slideDown().css('display','block');
              
              }
          });
      });
  })(jQuery);

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["product-tabs"]) return;
  try {
    
(function($){
    var $document = $(document);
    $document.ready(function(){
        $document.on('click', '.tabs', function(){
            var tabs = $(this),
                scope = tabs.parent().attr('data-tabs-scope');
            tabs.addClass('active').siblings().removeClass('active');
            $('.tabs-content[data-tabs-scope="' + scope + '"] .tab-content[data-identifier="' + tabs.attr('data-target') + '"]').addClass('active').siblings().removeClass('active');
            return false;
        });
    });
})(jQuery);

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["reviews"] && !window.DesignMode) return;
  try {
    
window.SectionReviews = (function(){
  function SectionReviews() {
    this.settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1019,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
      ]
    };

    this._run();
  }

  SectionReviews.prototype = {
    onLoad: function() {
      this._run();
    },

    _run: function() {
      var slider, autoplay, autoplaySpeed,
          settings = this.settings;

      jQuery('[id^="slider-reviews-"]').each(function(){
        slider = '#slider-' + $(this).data('slider');

        autoplay = jQuery(slider).data('autoplay');
        autoplaySpeed = jQuery(slider).data('speed');

        if (typeof autoplay !== 'undefined' && autoplay != true && autoplay != false) {
          autoplay = true;
        }

        if (typeof autoplaySpeed !== 'undefined' && autoplaySpeed < 800 && autoplaySpeed > 3000) {
          autoplaySpeed = 800;
        }

        settings.autoplay = autoplay;
        settings.autoplaySpeed = autoplaySpeed;

        jQuery(slider).not('.slick-initialized').slick( settings );
      });
    }
  };

  return new SectionReviews;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["shipping_bar"]) return;
  try {
    
  window.SectionShippingBar = (function(){
    function SectionShippingBar() {
      this._run();
    }
  
    SectionShippingBar.prototype = {
      _run: function() {
        update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
      },
  
      onSelect: function() {
        this._run();
      },
  
      onUnload: function() {
  
      }
    };
  
    return new SectionShippingBar;
    })();
  
  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["slider-promotional"] && !window.DesignMode) return;
  try {
    
window.SectionSliderPromotional = (function(){
  function SectionSliderPromotional() {
    this.settings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: '<button type="button" class="slick-prev">&lang;</button>',
      nextArrow: '<button type="button" class="slick-next">&rang;</button>',
      arrows: true,
      responsive: [
          {
              breakpoint: 1180,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
          },
          {
              breakpoint: 1019,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
          },
          {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
          }
      ]
    };

    this._run();
  }

  SectionSliderPromotional.prototype = {
    onLoad: function() {
      this._run();
    },

    _run: function() {
      var slider, autoplay, autoplaySpeed, settings = this.settings;

      jQuery('[id^="slider-promotional-"]').each(function(){
        slider = '#promotional-' + $(this).data('slider');
        
        autoplay = jQuery(slider).data('autoplay');
        autoplaySpeed = jQuery(slider).data('speed');

        if (typeof autoplay !== 'undefined' && autoplay != true && autoplay != false) {
          autoplay = true;
        }

        if (typeof autoplaySpeed !== 'undefined' && autoplaySpeed < 800 && autoplaySpeed > 3000) {
          autoplaySpeed = 800;
        }

        settings.autoplay = autoplay;
        settings.autoplaySpeed = autoplaySpeed;
        
        jQuery(slider).not('.slick-initialized').slick( settings );
      });
    }
  };

  return new SectionSliderPromotional;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["videos"] && !window.DesignMode) return;
  try {
    

window.SectionVideoSlider = (function(){
  function SectionVideoSlider() {
    this.settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev">&lang;</button>',
      nextArrow: '<button type="button" class="slick-next">&rang;</button>',
      infinite: true
    };

    this._run();
  }

  SectionVideoSlider.prototype = {
    onLoad: function() {
      this._run();
    },

    _run: function() {
      var slider,
          settings = this.settings;

      jQuery('[id^="video-slider-"]').each(function(){
        slider = '#slider-' + $(this).data('slider');

        jQuery(slider).not('.slick-initialized').slick( settings );
      });
    }
  };

  return new SectionVideoSlider;
})();


  } catch(e) { console.error(e); }
})();
})();
